import BasePlugin from '../BasePlugin'

export default class FinModCalcPort extends BasePlugin {
  async execute () {
    let recordId = this.context.getModel().id
    this.send({ recordId: recordId })
      .then((response) => {
        Object.values(this.context.getDashboardComponents()).forEach(function (item) {
          if (item[0].id === 252) {
            item[0].loadData()
          }
        })
      })
  }
}
